import React from 'react';


import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Devider  from '../../atoms/devider/devider';
import Text     from '../../atoms/text/text';
import Button   from '../../atoms/button/button';
import Stage    from '../stage/stage';

import styles from './stageUnterstuetzer.module.css'


const StageUnterstuetzer = () => (
  <Stage className={styles.stage} fullHeight={true} alignVert="middle" styleFull="light">
    <Headline tag="h2" visualStyle="h1">Werde Unterstützer!</Headline>
    <Slugline tag="h1">Zusammen sind wir stärker!</Slugline>
    <Devider />
    <Text align="center" className={styles.text}>
      Du hast bereits Ideen und Interesse, Dich mit uns auszutauschen?<br />
      Du kannst Dir eine Kooperation mit uns und unserer Plattform vorstellen?<br />
      Dann schicke uns eine Nachricht damit wir uns näher kennenlernen können.
    </Text>
    <Button href="#kontakt">Zusammen Unterstützen</Button>
  </Stage>
)
export default StageUnterstuetzer;
