import React        from "react";
import { Helmet }   from "react-helmet"

import Layout   from '../components/layout';

import StageUnterstuetzer    from '../components/molecules/stageUnterstuetzer/stageUnterstuetzer';
import StageKontakt          from '../components/molecules/stageContact/stageContact';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Helmet
      htmlAttributes={{
        lang: 'de'
      }}
    >
      <meta charSet="utf-8" />
      <title>Werde Unterstützer - ChancenCheckin</title>
      <meta name="description" content="Werde Unterstützer und hilf uns dabei Unternehmern unkompliziert die passenden informtionen zu liefern -  ChancenCheckin." />
      <link rel="canonical" href="https://www.chancencheckin.de/unterstuetzer" />
    </Helmet>

    <StageUnterstuetzer />

    <StageKontakt />
  </Layout>
)
